import { ApolloLink, Observable } from '@apollo/client';
import { context, SpanKind, propagation, trace, ROOT_CONTEXT } from '@opentelemetry/api';
import * as logger from '../Logger';

export class OTelLink extends ApolloLink {
    constructor() {
        super();
        this.tracer = trace.getTracer('OTelLink', '0.0.1');
    }

    _addPropagation(ctx, operation) {
        propagation.inject(ctx, operation, {
            set: (op, k, v) => op.setContext(({ headers }) => ({
                headers: {
                    [k]: String(v),
                    ...headers,
                },
            })),
        });
    }

    request(operation, forward) {
        const span = this.tracer.startSpan(operation.operationName, {
            kind: SpanKind.CLIENT,
        }, ROOT_CONTEXT);
        const ctx = trace.setSpan(ROOT_CONTEXT, span);
        this._addPropagation(ctx, operation);
        // @TODO add request events on span
        // It would be nice if we had span data that approached parity with @opentelemetry/instrumentation-fetch
        // see https://github.com/open-telemetry/opentelemetry-js/blob/main/packages/opentelemetry-instrumentation-fetch/src/fetch.ts
        // we don't need that right now but it will be useful if we publish and consume traces
        return new Observable((observer) => {
            let subscription;
            context.with(ctx, () => {
                subscription = forward(operation).subscribe({
                    next: (result) => {
                        // @TODO add response events on span
                        span.end();
                        logger.success(
                            // warning: not sure how stable these Span APIs are, upgrade carefully
                            span.name,
                            // span.duration format is an array of numbers, which when joined together have nanosecond precision
                            Math.ceil(Number(span.duration.join('') / 1000000)),
                        );
                        observer.next(result);
                    },
                    error: (error) => {
                        // @TODO add error events on span
                        span.end();
                        logger.failure(
                            // warning: not sure how stable these Span APIs are, upgrade carefully
                            span.name,
                            Math.ceil(Number(span.duration.join('') / 1000000)),
                            error,
                        );
                        observer.error(error);
                    },
                    complete: () => {
                        span.end();
                        observer.complete();
                    },
                });
            });
            return (() => {
                if (subscription) subscription.unsubscribe();
            });
        });
    }
}
